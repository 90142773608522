@import '~athena-next-ui-lib/dist/_global-vars.scss';
$radius: 4px;

.wizardOuter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  background: rgba(0, 0, 0, .25);
  align-items: center;
  justify-content: center;



  .wizardInner {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #404040;
    border-radius: $radius;
    border: 1px solid #467199;
    z-index: 2;

    box-shadow: 0 0 85px 25px #174671;

  }
}


$wizardTitleBg: #214562; //#111;

.wizardTitlebar {
  padding: 20px 20px 8px;
  background: $wizardTitleBg;
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: $fnt-lg;
  align-items: center;
  border-radius: $radius $radius 0 0;

  .wizardIcon {
    margin-right: 20px;

    >img {
      border-radius: 8px;
    }
  }

  .wizardTitle {
    font-size: 20px;
    font-weight: bold;

  }

  .wizardBar {
    margin: 8px;
    display: flex;
  }
}

.wizardTabsetContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.wizardTabset {
  background: $wizardTitleBg;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 1px solid rgb(58 109 152);

  .wizardTab {
    color: #fff;
    font-size: $fnt-lg;
    margin: 0 20px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    &.wizardTabSelected {
      color: $title-color;
      font-weight: bold;
      border-bottom-color: $title-color
    }

    &.hidden {
      display: none;
    }

    .label {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-items: center;
      line-height: 1;

      .icon {
        margin-right: 8px;

      }

      .labels {
        display: flex;
        flex-direction: column;
        margin: 0 8px;

        .majorLabel {
          display: flex;
          font-size: 16px;
          align-self: center;
        }

        .minorLabel {

          display: flex;
          font-size: 11px;
          color: rgba(255, 255, 255, .6);
          align-self: center;
          font-weight: normal !important;
          line-height: 1.5;
        }
      }



    }


    &.errors {
      color: goldenrod;
    }

  }
}



.hiddenContent {
  position: absolute;
  top: -10000px;
}

.wizardContent {
  position: relative;
  flex: 1 0 auto;
  min-width: 30vw;
  max-width: 60vw;

  color: #fff;
  padding: 8px 20px;
  transition: .5s all ease-in-out;

  overflow: hidden;

  &.list {
    max-height: 60vh;
    overflow: hidden;
  }

  &.form {
    max-height: 50vh;
    overflow: auto;
    @extend %custom-scrollbar;
  }

  &.noPad {
    padding: 0;
  }
}

.wizardCloseIcon {
  position: absolute;
  z-index: 2;
  z-index: 2;
  top: -10px;
  top: 12px;
  right: -10px;
  right: 12px;
  color: #fff;
  color: rgb(255, 255, 255);
  cursor: pointer;
  cursor: pointer;

  &:hover {
    color: #aaa;
    cursor: pointer;
  }
}

.wizardButtonBar {
  background: transparent; //$wizardTitleBg;
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  align-items: center;
  border-radius: 0 0 $radius $radius;
  width: 100%;

  border-top: 1px solid rgba(255, 255, 255, .1);

  >* {
    &:last-child {
      margin-left: auto !important;

    }
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.link {
  &:hover {
    color: $blue;
    border-bottom: 1px dotted $blue;
    cursor: pointer;
  }
}

//Theming
body:global(.lightTheme) {
  $txt-color: rgb(0, 0, 0);
  .wizardContent {
    color: $txt-color;
  }
  .wizardInner {
    background: rgb(255, 255, 255);
  }
  .wizardButtonBar {
    border-top-color:rgba(0, 0, 0, 0.25);
  }
 
}
body:global(.darkTheme) {
  $txt-color: rgb(255, 255, 255);
  $content-bg: rgb(56, 56, 56);
  .wizardContent {
    color: $txt-color;
  }
  .wizardInner {
    background: $content-bg;
  }
 
  .wizardButtonBar {
    border-top-color:rgba(255, 255, 255, 0.25);
   
  }
}