@import '~athena-next-ui-lib/dist/_global-vars.scss';

.settingsViewError {
    font: $fnt-main;
    padding: 20px 10px;
    color: goldenrod;
    font-weight: bold;
    font-size: $fnt-md;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.settingsView {
    //max-height: calc(100vh - 420px);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 49px);

    @extend %custom-scrollbar;

    .explanation {
        color: rgba(255, 255, 255, .75);
    }

    .icon {
        margin: 20px;
    }

    .viewTitle {
        margin-left: 4px;
        padding: 4px;
        font-size: medium;
        font-weight: 400;
    }

    .settingsContainer {
        margin: 0 8px 0 24px;
        display: flex;
        flex-wrap: wrap;
        font-size: $fnt-sm;


        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .fieldRow {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

    }

    .advancedTopBar,
    .basicTopBar {
        align-items: center !important;
        cursor: pointer;
        margin-left: $lg;
    }
}

//Theming
body:global(.darkTheme) {
    .settingsView {
        color: rgb(255, 255, 255);

        .explanation {
            color: rgba(255, 255, 255, .75);
        }
    }

    .icon {
        color: rgb(144, 202, 249);
    }

}

body:global(.lightTheme) {
    .settingsView {
        color: rgb(0, 0, 0);

        .explanation {
            color: rgba(0, 0, 0, .75);
        }
    }

    .icon {
        color: rgb(144, 202, 249);
    }

}