@import '~athena-next-ui-lib/dist/_global-vars.scss';

$source-color: #5AAACF; //#9080fa; //#ae97dc;
$source-color-txt: #abd9ef; //#b0a2ee; //lighten($source-color,15%);
$target-color: #efbe4d; //#E28E61; //#e39f23;
$target-color-txt: #fae0a5; //#f6c1a5; //lighten($target-color,30%);
$light-gray: rgba(255, 255, 255, .45);
$list-bg: rgb(0, 0, 0); //#202c36;//#222;
$list-border: rgba(255, 255, 255, .3);
$list-item-hover: rgba(255, 255, 255, .1);
$constructive: #bfefab;
$destructive: #f47272;
$deleted: red;


.sourceColor {
  color: $source-color-txt;
}

.targetColor {
  color: $target-color-txt;
}

.targetName {
  color: $target-color;
}

.replaceItem {
  color: $light-gray;
}

.sourceTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $fnt-lg;
  color: $light-gray;

  i {
    color: $source-color;
    font-style: normal;
  }
}

.targetTitle {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $fnt-lg;
  color: $light-gray;

  i {
    color: $target-color;
    font-style: normal;
  }
}

.deploymentSelectorRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 80px;
  align-items: center;
  margin: 8px;


}

.list {
  display: flex;
  line-height: 22px;
  flex-direction: column;
  align-self: stretch;
  border: 1px solid $list-border;
  overflow: auto;
  min-height: 200px;
  max-height: 200px;
  @extend %custom-scrollbar;
  background: $list-bg;

  .category {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $list-bg;
  }

  .item {
    z-index: 0;
  }




  .category,
  .item {
    padding: 2px 8px;

    .actionIcon {
      visibility: hidden;
      margin-left: 8px;
    }


    &:hover {
      background: $list-item-hover;

      .actionIcon {
        visibility: visible;
        cursor: pointer;

        &.constructive {
          color: $constructive !important;
        }

        &.destructive {
          color: $destructive !important;
        }
      }
    }

  }

  .category {
    font-weight: bold;
    color: steelblue;
  }

  .item {

    display: flex;
    flex-direction: row;

    padding: 4px 0 4px 10px;

    .itemLabel {
      border-bottom: 1px solid rgba(255, 255, 255, .1);

    }

    &:hover {
      .itemLabel {
        border-bottom: 1px solid transparent;
      }
    }

    &:last-child {
      .itemLabel {
        border-bottom: 1px solid transparent;
      }
    }


    &.deleted {
      color: $deleted;
      //text-decoration: line-through;
    }

    &.selected {
      color: $light-gray !important;
      text-decoration: line-through;
    }

    &.removed {
      color: #fff;
      background: $blue-dk;
      text-decoration: line-through;
    }
  }

  &.source {
    .category {
      color: $source-color;
    }

    .item {
      color: $source-color-txt;
    }
  }

  &.target {
    .category {
      color: $target-color;
    }

    .item {
      color: $target-color-txt;

      &.fromSource {
        color: $source-color-txt !important;
      }
    }


  }


}

.previewOuter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;


  &:before {
    display: flex;
    align-self: center;
    content: "preview";
    text-transform: uppercase;
    color: #ccc;
    font-size: 11px;
    position: absolute;
    left: calc(50% - 28px);
    top: 6px;
    z-index: 1;

  }

  &.source::before {
    color: $source-color;
  }

  &.target::before {
    color: $target-color;
  }
}

.preview {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  max-height: 120px;
  width: 100%;
  margin: 12px 0;
  border: 1px solid $list-border;
  overflow: auto;
  @extend %custom-scrollbar;
  padding: 8px;
  background: $list-bg;




  h3 {
    margin: 0
  }



  .title {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    font-size: $fnt-md;
  }

  .lastModified {
    color: rgba(255, 255, 255, .75);
    font-size: $fnt-xs;
  }

}


.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  +.column {
    margin-left: 10px;
  }
}




.previewOn,
.previewOff {
  padding: 0 8px 0 0;

  .source & {
    color: $source-color;
  }

  .target & {
    color: $target-color;
  }

  &:hover {
    cursor: pointer;
  }

}

.previewOff {

  .source & {
    color: $light-gray;
  }

  .target & {
    color: $light-gray;
  }
}


.lastModifiedRow {
  font-size: $fnt-sm;
  color: rgba(255, 255, 255, .5);
}


/*-------------
Rule Display >>
-------------*/


.tagName {
  color: #fff; //goldenrod;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.ruleSummary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 8px 4px 0;
  font: $fnt-main-mono;

  .ruleNum {
    display: flex;
    width: 15px;
    align-items: flex-end;
    justify-content: flex-end;
    color: $title-color;
    margin-right: 8px;
  }

  .rules {
    display: flex;
    flex-direction: column;
  }

  .ruleAnd {
    margin: 0 $lg;
    color: $blue;
  }

  .ruleParts {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 0;
  }

  .rulePart {
    $c: rgba(0, 0, 0, .75);
    $c2: rgba(255, 255, 255, .25);

    display: flex;
    border: 1px solid $c2;
    border-radius: 2px;
    color: rgba(255, 255, 255, .8);


    font-size: $fnt-sm;

    .ruleKey {
      display: flex;
      align-items: center;
      background: $c;
      padding: 0 $md;
      text-transform: uppercase;
      font-size: $fnt-xxs;
    }

    .ruleValue {
      padding: $xs $md;
      color: rgba(255, 255, 255, 1);
    }


  }


}

.channel {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $md;
  border: 1px solid rgba(255, 255, 255, .2);
  cursor: default;
  background: transparent; //#39738b;
  color: #59b1d5;
  margin: 0 $md;
  border-radius: 2px;

  >b {
    color: rgba(255, 255, 255, .5);
    font-weight: normal;
  }
}

.tagTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.tagType {
  font: $fnt-main-mono;
  margin: $sm $md 0 0;
  border: 1px solid rgba(255, 255, 255, .4);
  padding: 1px $sm;
  color: rgba(255, 255, 255, 1);
  min-width: 50px;
  justify-content: center;
  font-weight: bold;
  border-radius: 2px;

  &.manual {
    background: #2680d0;
  }

  &.auto {
    background: #177c43;
  }


}

.error {
  color: #ea8080;
  font-style: italic;
  font-size: $fnt-md;
  font-weight: normal;
  margin-left: 8px;

}

.integrationHeader {
  display: flex;
  flex-direction: row;
  gap: 8px;
}



//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  $source-color: #000;
  $source-color-txt: #000;
  $target-color: #000;
  $target-color-txt: #000;
  $gray: rgba(0, 0, 0, .4);
  $list-bg: rgb(240, 240, 240);
  $list-border: rgba(0, 0, 0, .15);
  $list-item-hover: rgba(0, 0, 0, .1);
  $constructive: #419d1a;
  $destructive: #f47272;
  $deleted: red;
  $previewOn: $blue-dk;
  $previewOff: rgba(0, 0, 0, .3);

  .sourceColor {
    color: $source-color-txt;
  }

  .targetColor {
    color: $target-color-txt;
  }

  .targetName {
    color: $target-color;
  }

  .replaceItem {
    color: $gray;
  }

  .sourceTitle {
    color: $gray;

    i {
      color: $source-color;
    }
  }

  .targetTitle {
    color: $gray;

    i {
      color: $target-color;
    }
  }

  .list {
    border: 1px solid $list-border;
    @extend %custom-scrollbar-light;
    background: $list-bg;

    .category {
      background: $list-bg;

    }

    .category,
    .item {


      &:hover {
        background: $list-item-hover;

        .actionIcon {

          &.constructive {
            color: $constructive !important;
          }

          &.destructive {
            color: $destructive !important;
          }
        }
      }

    }

    .category {
      color: steelblue;
    }

    .item {
      .itemLabel {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
      }

      &:hover {
        .itemLabel {
          border-bottom: 1px solid transparent;
        }
      }

      &:last-child {
        .itemLabel {
          border-bottom: 1px solid transparent;
        }
      }


      &.deleted {
        color: $deleted;
      }

      &.selected {
        color: $gray !important;
      }

      &.removed {
        color: #fff;
        background: $blue-dk;
      }
    }

    &.source {
      .category {
        color: $source-color;
      }

      .item {
        color: $source-color-txt;
      }
    }

    &.target {
      .category {
        color: $target-color;
      }

      .item {
        color: $target-color-txt;

        &.fromSource {
          color: $source-color-txt !important;
        }
      }


    }


  }

  .previewOuter {

    &:before {
      color: #ccc;
    }

    &.source::before {
      color: $source-color;
    }

    &.target::before {
      color: $target-color;
    }
  }

  .preview {
    border: 1px solid $list-border;
    @extend %custom-scrollbar-light;
    background: $list-bg;

    .title {
      color: rgba(0, 0, 0, 1);

    }

    .lastModified {
      color: rgba(0, 0, 0, .75);
    }

  }





  .source,
  .target {
    .previewOff {
      color: $previewOff;
    }

    .previewOn {
      color: $previewOn;
    }
  }


  .lastModifiedRow {
    color: rgba(0, 0, 0, .5);
  }

  .tagName {
    color: #fff; //goldenrod;

  }

  .ruleSummary {


    .ruleNum {
      color: $gray;
    }

    .ruleAnd {
      color: $blue-dk;
    }

    .rulePart {
      $c: rgba(0, 0, 0, .1);
      $c2: rgba(0, 0, 0, .25);
      $c3: rgba(0, 0, 0, 1);

      border: 1px solid $c2;

      color: $c3;

      .ruleKey {
        background: $c;
        color: $c3;


      }

      .ruleValue {
        color: $c3;
      }


    }


  }

  .channel {
    border: 1px solid rgba(0, 0, 0, .2);
    color: #59b1d5;

    >b {
      color: rgba(0, 0, 0, .5);
    }
  }


  .tagType {
    border: 1px solid rgba(0, 0, 0, .4);
    color: rgba(0, 0, 0, 1);


    &.manual {
      background: #2680d0;
    }

    &.auto {
      background: #177c43;
    }


  }

  .error {
    color: #ea8080;
  }

}