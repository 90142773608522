@import '../../node_modules/athena-next-ui-lib/dist/global-vars';

.webhookType {
  display: flex;
  font-size: $fnt-sm;
  color: #fff;
  padding: $xs $sm;
  border: 1px solid rgba(255, 255, 255, .2);
  background: #525252;
  white-space: nowrap;
  align-items: flex-start;
  justify-content: center;
  border-radius: 2px;
  min-width: 100px;
}

.toggleOn {
  color: #efefef;
}

.toggleOff {
  color: rgba(255, 255, 255, .25);
}

.instruction {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;


  >* {
    margin: $md;
  }

  .right {
    margin-left: auto;
  }
}

.page {
  display: flex;
  flex-direction: column;
  margin: $md;
  width: calc(100vw - 70px);
}

.hr {
  width: 100%;
  opacity: 0.3;
}

.formError {
  display: flex;
  align-items: center;
  width: 100%;
  background: #000;
  color: goldenrod;
  padding: $sm $md;
  border-radius: 2px;
}

.formErrorHidden {
  display: none;
}


.tokenContent {
  display: block;

  .copyIcon {
    display: none;
  }

  &:hover .copyIcon {
    display: inline-block;
    cursor: pointer;
  }

}

//Theming


body:global(.lightTheme) {

  .formError {
    background: rgb(204, 204, 204);
    color: rgb(204, 0, 0);
  }
}